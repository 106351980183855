const apple1vid = [
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x7e, 0x7e, 0x7e, 0x7e, 0x7e, 0x7e, 0x7e, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x08, 0x08, 0x08, 0x08, 0x08, 0x00, 0x08, 0x00],
    [0x14, 0x14, 0x14, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x14, 0x14, 0x3e, 0x14, 0x3e, 0x14, 0x14, 0x00],
    [0x08, 0x3c, 0x0a, 0x1c, 0x28, 0x1e, 0x08, 0x00],
    [0x06, 0x26, 0x10, 0x08, 0x04, 0x32, 0x30, 0x00],
    [0x04, 0x0a, 0x0a, 0x04, 0x2a, 0x12, 0x2c, 0x00],
    [0x08, 0x08, 0x08, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x08, 0x04, 0x02, 0x02, 0x02, 0x04, 0x08, 0x00],
    [0x08, 0x10, 0x20, 0x20, 0x20, 0x10, 0x08, 0x00],
    [0x08, 0x2a, 0x1c, 0x08, 0x1c, 0x2a, 0x08, 0x00],
    [0x00, 0x08, 0x08, 0x3e, 0x08, 0x08, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x08, 0x08, 0x04, 0x00],
    [0x00, 0x00, 0x00, 0x3e, 0x00, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x08, 0x00],
    [0x00, 0x20, 0x10, 0x08, 0x04, 0x02, 0x00, 0x00],
    [0x1c, 0x22, 0x32, 0x2a, 0x26, 0x22, 0x1c, 0x00],
    [0x08, 0x0c, 0x08, 0x08, 0x08, 0x08, 0x1c, 0x00],
    [0x1c, 0x22, 0x20, 0x18, 0x04, 0x02, 0x3e, 0x00],
    [0x3e, 0x20, 0x10, 0x18, 0x20, 0x22, 0x1c, 0x00],
    [0x10, 0x18, 0x14, 0x12, 0x3e, 0x10, 0x10, 0x00],
    [0x3e, 0x02, 0x1e, 0x20, 0x20, 0x22, 0x1c, 0x00],
    [0x38, 0x04, 0x02, 0x1e, 0x22, 0x22, 0x1c, 0x00],
    [0x3e, 0x20, 0x10, 0x08, 0x04, 0x04, 0x04, 0x00],
    [0x1c, 0x22, 0x22, 0x1c, 0x22, 0x22, 0x1c, 0x00],
    [0x1c, 0x22, 0x22, 0x3c, 0x20, 0x10, 0x0e, 0x00],
    [0x00, 0x08, 0x08, 0x00, 0x00, 0x08, 0x08, 0x00],
    [0x00, 0x00, 0x08, 0x00, 0x08, 0x08, 0x04, 0x00],
    [0x10, 0x08, 0x04, 0x02, 0x04, 0x08, 0x10, 0x00],
    [0x00, 0x00, 0x3e, 0x00, 0x3e, 0x00, 0x00, 0x00],
    [0x04, 0x08, 0x10, 0x20, 0x10, 0x08, 0x04, 0x00],
    [0x1c, 0x22, 0x10, 0x08, 0x08, 0x00, 0x08, 0x00],
    [0x1c, 0x22, 0x2c, 0x2a, 0x2a, 0x2a, 0x1c, 0x00],
    [0x08, 0x14, 0x22, 0x22, 0x3e, 0x22, 0x22, 0x00],
    [0x1e, 0x22, 0x22, 0x1e, 0x22, 0x22, 0x1e, 0x00],
    [0x1c, 0x22, 0x02, 0x02, 0x02, 0x22, 0x1c, 0x00],
    [0x1e, 0x22, 0x22, 0x22, 0x22, 0x22, 0x1e, 0x00],
    [0x3e, 0x02, 0x02, 0x1e, 0x02, 0x02, 0x3e, 0x00],
    [0x3e, 0x02, 0x02, 0x1e, 0x02, 0x02, 0x02, 0x00],
    [0x1c, 0x22, 0x02, 0x3a, 0x22, 0x22, 0x1c, 0x00],
    [0x22, 0x22, 0x22, 0x3e, 0x22, 0x22, 0x22, 0x00],
    [0x1c, 0x08, 0x08, 0x08, 0x08, 0x08, 0x1c, 0x00],
    [0x38, 0x10, 0x10, 0x10, 0x10, 0x12, 0x0c, 0x00],
    [0x22, 0x12, 0x0a, 0x06, 0x0a, 0x12, 0x22, 0x00],
    [0x02, 0x02, 0x02, 0x02, 0x02, 0x02, 0x3e, 0x00],
    [0x22, 0x36, 0x2a, 0x2a, 0x22, 0x22, 0x22, 0x00],
    [0x22, 0x22, 0x26, 0x2a, 0x32, 0x22, 0x22, 0x00],
    [0x1c, 0x22, 0x22, 0x22, 0x22, 0x22, 0x1c, 0x00],
    [0x1e, 0x22, 0x22, 0x1e, 0x02, 0x02, 0x02, 0x00],
    [0x1c, 0x22, 0x22, 0x22, 0x2a, 0x12, 0x2c, 0x00],
    [0x1e, 0x22, 0x22, 0x1e, 0x22, 0x22, 0x22, 0x00],
    [0x1c, 0x22, 0x02, 0x1c, 0x20, 0x22, 0x1c, 0x00],
    [0x3e, 0x08, 0x08, 0x08, 0x08, 0x08, 0x08, 0x00],
    [0x22, 0x22, 0x22, 0x22, 0x22, 0x22, 0x1c, 0x00],
    [0x22, 0x22, 0x22, 0x22, 0x22, 0x14, 0x08, 0x00],
    [0x22, 0x22, 0x22, 0x2a, 0x2a, 0x2a, 0x14, 0x00],
    [0x22, 0x22, 0x14, 0x08, 0x14, 0x22, 0x22, 0x00],
    [0x22, 0x22, 0x22, 0x14, 0x08, 0x08, 0x08, 0x00],
    [0x3e, 0x20, 0x10, 0x08, 0x04, 0x02, 0x3e, 0x00],
    [0x3e, 0x06, 0x06, 0x06, 0x06, 0x06, 0x3e, 0x00],
    [0x00, 0x02, 0x04, 0x08, 0x10, 0x20, 0x00, 0x00],
    [0x3e, 0x30, 0x30, 0x30, 0x30, 0x30, 0x3e, 0x00],
    [0x00, 0x00, 0x08, 0x14, 0x22, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
    [0x1c, 0x22, 0x2c, 0x2a, 0x2a, 0x2a, 0x1c, 0x00],
    [0x08, 0x14, 0x22, 0x22, 0x3e, 0x22, 0x22, 0x00],
    [0x1e, 0x22, 0x22, 0x1e, 0x22, 0x22, 0x1e, 0x00],
    [0x1c, 0x22, 0x02, 0x02, 0x02, 0x22, 0x1c, 0x00],
    [0x1e, 0x22, 0x22, 0x22, 0x22, 0x22, 0x1e, 0x00],
    [0x3e, 0x02, 0x02, 0x1e, 0x02, 0x02, 0x3e, 0x00],
    [0x3e, 0x02, 0x02, 0x1e, 0x02, 0x02, 0x02, 0x00],
    [0x1c, 0x22, 0x02, 0x3a, 0x22, 0x22, 0x1c, 0x00],
    [0x22, 0x22, 0x22, 0x3e, 0x22, 0x22, 0x22, 0x00],
    [0x1c, 0x08, 0x08, 0x08, 0x08, 0x08, 0x1c, 0x00],
    [0x38, 0x10, 0x10, 0x10, 0x10, 0x12, 0x0c, 0x00],
    [0x22, 0x12, 0x0a, 0x06, 0x0a, 0x12, 0x22, 0x00],
    [0x02, 0x02, 0x02, 0x02, 0x02, 0x02, 0x3e, 0x00],
    [0x22, 0x36, 0x2a, 0x2a, 0x22, 0x22, 0x22, 0x00],
    [0x22, 0x22, 0x26, 0x2a, 0x32, 0x22, 0x22, 0x00],
    [0x1c, 0x22, 0x22, 0x22, 0x22, 0x22, 0x1c, 0x00],
    [0x1e, 0x22, 0x22, 0x1e, 0x02, 0x02, 0x02, 0x00],
    [0x1c, 0x22, 0x22, 0x22, 0x2a, 0x12, 0x2c, 0x00],
    [0x1e, 0x22, 0x22, 0x1e, 0x22, 0x22, 0x22, 0x00],
    [0x1c, 0x22, 0x02, 0x1c, 0x20, 0x22, 0x1c, 0x00],
    [0x3e, 0x08, 0x08, 0x08, 0x08, 0x08, 0x08, 0x00],
    [0x22, 0x22, 0x22, 0x22, 0x22, 0x22, 0x1c, 0x00],
    [0x22, 0x22, 0x22, 0x22, 0x22, 0x14, 0x08, 0x00],
    [0x22, 0x22, 0x22, 0x2a, 0x2a, 0x2a, 0x14, 0x00],
    [0x22, 0x22, 0x14, 0x08, 0x14, 0x22, 0x22, 0x00],
    [0x22, 0x22, 0x22, 0x14, 0x08, 0x08, 0x08, 0x00],
    [0x3e, 0x20, 0x10, 0x08, 0x04, 0x02, 0x3e, 0x00],
    [0x3e, 0x06, 0x06, 0x06, 0x06, 0x06, 0x3e, 0x00],
    [0x00, 0x02, 0x04, 0x08, 0x10, 0x20, 0x00, 0x00],
    [0x3e, 0x30, 0x30, 0x30, 0x30, 0x30, 0x3e, 0x00],
    [0x00, 0x00, 0x08, 0x14, 0x22, 0x00, 0x00, 0x00],
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
];

export default apple1vid;
